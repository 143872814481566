<template>
  <v-container>
    <Loading :loadingCounter="loadingCounter" />
    <v-row justify="end" align="center">
      <v-img
        id="company-logo"
        alt="company Logo"
        class="shrink mr-2"
        contain
        src="../assets/img/nikkon_logo.png"
        transition="scale-transition"
        width="50"
      />
      <v-img
        id="company-name-logo"
        alt="company Name Logo"
        class="shrink mr-2"
        contain
        src="../assets/img/nikkon_name_logo.png"
        transition="scale-transition"
        width="250"
      />
    </v-row>
    <v-row justify="center" align-content="center">
      <v-col>
        <v-card id="login-form">
          <div id="form-layout">
            <div id="card-title">
              <v-row justify="center" align="center">
                <v-img
                  id="system-logo"
                  alt="system Logo"
                  class="shrink mr-2"
                  contain
                  src="../assets/img/cargos.png"
                  transition="scale-transition"
                  width="184"
                />
              </v-row>
            </div>
            <v-form id="input-area" ref="loginForm" v-model="validationResult">
              <p class="text-left">ユーザーID</p>
              <v-text-field
                id="txt-user-id"
                class="input-text-fielld"
                placeholder="ユーザーID"
                v-model="userId"
                :rules="[rules.required, rules.userLength]"
                maxlength="20"
                @input="formValidation"
                autoComplete="login-username"
              ></v-text-field>
              <p class="text-left">パスワード</p>
              <v-text-field
                id="txt-password"
                class="input-text-fielld"
                placeholder="パスワード"
                v-model="userPassword"
                type="password"
                :rules="[rules.required, rules.passwordLength]"
                maxlength="20"
                @input="formValidation"
                autoComplete="login-current-password"
              ></v-text-field>
              <v-btn id="btn-login" @click="login" :disabled="!validationResult">ログイン</v-btn>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- ダイアログ -->
    <v-dialog v-model="resultDialog.isOpen" width="75%">
      <v-card>
        <v-card-title class="headline grey lighten-2">結果</v-card-title>
        <v-card-text>
          <pre
            >{{ resultDialog.message }}
          </pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { appConfig } from "../assets/scripts/js/AppConfig";
import Loading from "@/components/loading";
export default {
  name: "Login",
  components: {
    Loading,
  },
  data: () => ({
    loadingCounter: 0,
    userId: "",
    userPassword: "",
    validationResult: false,
    resultDialog: { isOpen: false, message: "" },
    rules: {
      required: (value) =>
        !!value || messsageUtil.getMessage("P-COM-001_002_E", ["ユーザー名", "パスワード"]),
      // maxLengthがあるので実質動作しない。
      userLength: (value) =>
        (value && value.length >= 1 && value.length <= 20) ||
        1 + "～" + 20 + "文字で入力してください",
      // maxLengthがあるので実質動作しない。
      passwordLength: (value) =>
        (value && value.length >= 6 && value.length <= 20) ||
        6 + "～" + 20 + "文字で入力してください",
    },
  }),
  created() {
    // 御製ログアウトになって場合、警告メッセージとcognitoデータ削除
    if (sessionStorage.getItem("username") != null) {
      this.$httpClient.logout(sessionStorage.getItem("username"));
      const message = messsageUtil.getMessage("P-COM-001_003_W");
      this.resultDialog.message = message;
      this.resultDialog.isOpen = true;
    }
  },
  mounted() {},
  methods: {
    login() {
      this.loadingCounter = 1;
      this.$httpClient
        .login(this.userId, this.userPassword)
        .then((result) => {
          console.log(result.accessToken.payload.username + " login success.");
          // 警告灯フラグを取得し、セッションに設定
          sessionStorage.setItem("pt", this.$route.query.pt);

          // 画面の権限を持っていない場合、ログアウトしてログインをエラーとします。
          const roles = sessionStorage.getItem("role");
          const role = roles.split(",")[0];
          let canScreen = screenAuth.canScreenAuth(role, appConfig.SCREEN_ID.P_COM_001);

          if (canScreen) {
            this.$httpClient.getApiGatewayCredentials().then(() => {
              this.$router.push("progress_summary");
            });
          } else {
            this.$httpClient.logout();
            throw "You do not have permission to use the screen";
          }
        })
        .catch((ex) => {
          console.error("login error", ex);
          const message = messsageUtil.getMessage("P-COM-001_001_E");
          this.resultDialog.message = message;
          this.resultDialog.isOpen = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    formValidation() {
      this.$refs.loginForm.validate();
    },
    close() {
      this.resultDialog.isOpen = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
::v-deep #login-form {
  padding: 2rem;
  width: 30%;
  min-width: 30rem;
  margin: 10rem auto;
  border: 2px solid #a6a6a6;
  border-radius: 3rem;
}
#company-logo {
  width: 30px;
  height: 30px;
}
#lbl-system-name {
  width: 100%;
}
#input-area {
  margin: auto;
  max-width: 15rem;
  text-align: center;
}
::v-deep #card-title {
  font-size: x-large;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 20px;
}
::v-deep .input-text-fielld {
  margin-top: 0px;
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        input {
          margin-top: 0px;
          padding: 5px;
          border: 3px solid #d7e4bd !important;
        }
      }
    }
  }
}
::v-deep #btn-login {
  background: #73c7ea;
  color: white;
  font-weight: bold;
  width: 10rem;
}
p {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 14px;
}
</style>
